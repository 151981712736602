import axios from 'axios'

import config from '@/config'
import { usePreview } from '@/services/providers/PreviewContext'

const characters =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

const randomImageName = (extension: string) => {
  const charactersLength = characters.length

  let result = ''

  for (let i = 0; i < 16; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result + extension
}

export const useUploadImage = (baseUploadUrl: string) => {
  const { setPreviews } = usePreview()

  const handleUploadImage = async (photoId: string, image: File) => {
    try {
      const imageExtension = '.' + image.name.split('.').pop()
      const name = randomImageName(imageExtension)
      const url = `${config.S3_BASE_URL}/${baseUploadUrl}/${name}`

      await axios.put(url, image, {
        headers: {
          'Content-Type': image.type,
          'x-amz-acl': 'public-read-write'
        },
        onUploadProgress: ({ loaded, total }) => {
          if (total) {
            const progress = Math.round((loaded * 100) / total)
            const hasRemotelyUploaded = progress == 100

            setPreviews(state =>
              state.map(preview =>
                preview.id === photoId
                  ? {
                      ...preview,
                      uploadProgress: progress,
                      hasRemotelyUploaded
                    }
                  : preview
              )
            )
          }
        }
      })

      return url
    } catch (err) {
      console.error('handleUploadImage error ', err)

      return null
    }
  }

  return handleUploadImage
}
