export const retry = async (
  maxRetries: number,
  fn: () => Promise<any>,
  delay = 3000
): Promise<any> => {
  try {
    return await fn()
  } catch (error) {
    if (maxRetries <= 0) {
      throw error
    }

    return new Promise(resolve =>
      setTimeout(() => resolve(retry(maxRetries - 1, fn, delay)), delay)
    )
  }
}
